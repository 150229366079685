












import Vue, {PropType} from "vue";

  export default Vue.extend({
    name: "google-map" as string,

    props: {
      latitude: Number as PropType<Number>,
      longitude: Number as PropType<Number>
    }
  })
