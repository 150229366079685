












































































































































import Vue, {PropType} from "vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import {CountryCodes, TimezoneData, VenueData} from "@/types";
import {mapActions, mapGetters} from "vuex";
import {email,decimal} from "vuelidate/lib/validators";

export default Vue.extend({
  name: "extended-info" as string,

  components: {
    vSelect
  },

  data() {
    return {
      venueEntityName: "",
      venueAddress1: "",
      venueLatitude: "",
      venueLongitude: "",
      venueCity: "",
      venueCountry: "",
      venueTimezone: "Time zone",
      venueContactPerson: "",
      venueContactPhone: "",
      venueContactEmail: "",
      isSubmitted:false,
      lat:null,
      long:null,
    }
  },
  //@ts-ignore
  validations: {
    venueContactEmail: { email },
    venueContactPhone: {
      checkPhoneNumber(phoneNumber) {
        if(phoneNumber === null || phoneNumber === '') return true;
        return (/^[+\d](?:.*\d|\s*)(?:.*\d|\s*)?$/.test(phoneNumber));
      }
    },
    venueLatitude: {decimal,
      checkLatitude(venueLatitude) {
        let latitudeRegex = new RegExp("^(\\+|-)?(?:90(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]{1,30})?))$");
        return latitudeRegex.test(venueLatitude)
    }
    },
    venueLongitude: {decimal,
      checkLongitude(venueLongitude) {
        let longitudeRegex = new RegExp("^(\\+|-)?(?:180(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\\.[0-9]{1,30})?))$");
        return longitudeRegex.test(venueLongitude)
    }
    },
  },

  props: {
    edit: Boolean as PropType<Boolean>,
    venue: Object as PropType<VenueData>
  },

  async mounted(): Promise<void> {
    await this.setCountryCode();
    await this.setTimezones();
    // this.updateVenueExtendedData();
  },

  updated() {
    // this.updateVenueExtendedData();
  },

  computed: {
    ...mapGetters("venueModule", {
      countryCodes: "GET_COUNTRY_CODES"
    }),
    ...mapGetters("companyModule", {
      company: "GET_COMPANY"
    }),
    ...mapGetters("globalModule", {
      timezones: "GET_TIMEZONES"
    }),
    selectedCountryCode(): CountryCodes | string {
      const selectedCountry = this.countryCodes.find(c => c.id === this.venue.country_id);
      if (!selectedCountry) return "";
      return selectedCountry;
    },
    selectedCountryCodeName(): string {
      return this.selectedCountryCode.name;
    },
    getTimezoneName(): string {
      if (!this.venue.timezone_id || !this.timezones.length) return "";
      return this.timezones.find(t => t.id === this.venue.timezone_id).timezone;
    },
    getTimezoneId(): string {
      return this.timezones.find(t => t.country_code.toLowerCase() === this.venueCountry.code.toLowerCase()).id;
    },
  },

  methods: {
    ...mapActions("venueModule", {
      updateVenueData: "UPDATE_VENUE",
      setCountryCode: "SET_COUNTRY_CODES"
    }),
    ...mapActions("globalModule", {
      setTimezones: "FETCH_TIMEZONES"
    }),
    updateVenueExtendedData(): void {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$emit('setVenueExtendedData', false);
        return
      }

      const venuePayload: object = {
        legal_entity_name: this.venueEntityName,
        address1: this.venueAddress1,
        address2: "090 01",
        latitude: Number(this.venueLatitude),
        longitude: Number(this.venueLongitude),
        city: this.venueCity,
        country_id: this.venueCountry.id,
        timezone_id: this.getTimezoneId,
        state: 3,
        contact: this.venueContactPerson,
        contact_tel: this.venueContactPhone,
        contact_email: this.venueContactEmail
      }
      this.$emit('setVenueExtendedData', venuePayload);
      this.isSubmitted = false;
    }
  },

  watch: {
    venue: {
      handler(value): void {
        this.venueEntityName = !!this.venue.legal_entity_name ? this.venue.legal_entity_name : "";
        this.venueAddress1 = !!this.venue.address1 ? this.venue.address1 : "";
        this.venueLatitude = !!this.venue.latitude ? this.venue.latitude : "";
        this.venueLongitude = !!this.venue.longitude ? this.venue.longitude : "";
        this.venueCity = !!this.venue.city ? this.venue.city : "";
        this.venueCountry = this.selectedCountryCode;
        this.venueContactPerson = !!this.venue.contact ? this.venue.contact : "";
        this.venueContactPhone = !!this.venue.contact_tel ? this.venue.contact_tel : "";
        this.venueContactEmail = !!this.venue.contact_email ? this.venue.contact_email : "";
      },
      deep: true
    },
          edit: {
      handler(value):void {
        if(value){
          this.updateVenueExtendedData()
        }
      }
    }
  }
})
