const createImage = {
  methods: {
    createImage(file, imageSelector, MAX_WIDTH = 200, MAX_HEIGHT = 200) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const imgElement = document.querySelector(imageSelector);
        imgElement.src = e.target.result;
        imgElement.onload = (e) => {
          const canvas = document.createElement("canvas");
          let width = imgElement.naturalWidth;
          let height = imgElement.naturalHeight;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d");
          ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);

          const srcEncoded = ctx.canvas.toDataURL("image/jpeg",1);
          this.item = srcEncoded;
        };
      };
    },
  }
};

export default createImage;
