


















































import Vue, {PropType} from "vue";
import {VenueData} from "@/types";
import {mapActions, mapGetters} from "vuex";
import createImage from "@/mixins/CreateImage";

export default Vue.extend({
  name: "general-info" as string,

  data() {
    return {
      venueName: "",
      venueDescription: "",
      item: false,
      submitStatus: "",
    }
  },
  mixins: [createImage],
  props: {
    edit: Boolean as PropType<Boolean>,
    venue: Object as PropType<VenueData>
  },
  mounted() {
    this.venueName = !!this.venue.name ? this.venue.name : "";
    this.venueDescription = !!this.venue.description ? this.venue.description.replace(/\s\s+/g, ' ') : "";
  },

  updated() {
    this.updateVenueGeneralData();
  },



  computed: {
    ...mapGetters("venueModule", {
      isVenueImage: "IS_VENUE_IMAGE"
    }),
    insertVenueImage(): string {
      if (!this.venue.image) return "";
      return `data:image/jpeg;charset=utf-8;base64, ${this.venue.image}`;
    },
    formatImage(): string {
      return this.item.split(',')[1];
    }
  },

  methods: {
    ...mapActions("venueModule", {
      updateVenueData: "UPDATE_VENUE"
    }),
    ...mapActions("globalModule", {
      setImage: "FETCH_ENTITY_IMAGE"
    }),
    previewFile(item, e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.createImage(files[0],".venue-image");
    },

    updateVenueGeneralData(): void {
      const venuePayload: object = {
        name: this.venueName,
        description: this.venueDescription,
      }

        this.$emit('setVenueGeneralData', venuePayload);

    },
    uploadVenueImage(): void {
      setTimeout(() => {
        this.$emit('setVenuePhoto', this.formatImage);
      }, 250)
    }
  },

  watch: {
    venue: {
      handler(value): void {
        this.venueName = !!this.venue.name ? this.venue.name : "";
        this.venueDescription = !!this.venue.description ? this.venue.description.replace(/\s\s+/g, ' ') : "";
        this.item = !!this.venue.image_last_updated ? this.insertVenueImage : false;
      },
      deep: true
    }
  }
})
