






































































































































































































































































































































import Vue, {PropType} from "vue";
import VueTimepicker from 'vue2-timepicker/src'
import {mapActions, mapGetters} from "vuex";
import { VenueOpeningHoursData } from '@/types';

export default Vue.extend({
  name: "opening-hours" as string,

  components: {
    VueTimepicker
  },

  props: {
    edit: Boolean as PropType<Boolean>
  },

  data() {
    return {
      mondayHours: {
        opening: "00:00",
        closing: "00:00",
        isClosed: false
      },
      tuesdayHours: {
        opening: "00:00",
        closing: "00:00",
        isClosed: false
      },
      wednesdayHours: {
        opening: "00:00",
        closing: "00:00",
        isClosed: false
      },
      thursdayHours: {
        opening: "00:00",
        closing: "00:00",
        isClosed: false
      },
      fridayHours: {
        opening: "00:00",
        closing: "00:00",
        isClosed: false
      },
      saturdayHours: {
        opening: "00:00",
        closing: "00:00",
        isClosed: false
      },
      sundayHours: {
        opening: "00:00",
        closing: "00:00",
        isClosed: false
      }
    }
  },

  async mounted(): Promise<void> {
    await this.setVenueOpeningHours();
    this.setVenueHours();
  },

  computed: {
    ...mapGetters("venueModule", {
      venueOpeningHours: "GET_VENUE_OPENING_HOURS"
    })
  },

  methods: {
    ...mapActions("venueModule", {
      setVenueOpeningHours: "FETCH_VENUE_OPENING_HOURS"
    }),
    setOpeningHours(isClosed: boolean, open: string, close: string): string {
      if (open === "00:00:00" && close === "23:59:00") return "Nonstop"
      if (isClosed) return this.$t("Closed");
      if (!isClosed) return `${this.formatOpeningHours(open)} - ${this.formatOpeningHours(close)}`;
    },
    formatOpeningHours(value: string): string {
      return value.substring(0, value.length - 3);
    },
    formatOpeningHoursForApi(isClosed: boolean, value: string): string {
      if (isClosed) return "00:00:00";
      if (value.length < 8) return value + ":00";
      return value;
    },
    formatClosingHoursForApi(isClosed: boolean, open: string, close: string): string {
      if (isClosed) return "00:00:00";
      if (open === "00:00" && close === "00:00") return "23:59:00";
      if (close.length < 8) return close + ":00";
      return close;
    },
    filterHoursByWeekDay(weekDay: number): VenueOpeningHoursData {
      return this.venueOpeningHours.find(h => h.week_day === weekDay);
    },
    setVenueHours(): void {
      this.mondayHours.opening = this.filterHoursByWeekDay(0).venue_opening;
      this.mondayHours.closing = this.filterHoursByWeekDay(0).venue_closing;
      this.mondayHours.isClosed = this.filterHoursByWeekDay(0).venue_closing == "00:00:00" && this.filterHoursByWeekDay(0).venue_opening == "00:00:00";

      this.tuesdayHours.opening = this.filterHoursByWeekDay(1).venue_opening;
      this.tuesdayHours.closing = this.filterHoursByWeekDay(1).venue_closing;
      this.tuesdayHours.isClosed = this.filterHoursByWeekDay(1).venue_closing == "00:00:00" && this.filterHoursByWeekDay(1).venue_opening == "00:00:00";

      this.wednesdayHours.opening = this.filterHoursByWeekDay(2).venue_opening;
      this.wednesdayHours.closing = this.filterHoursByWeekDay(2).venue_closing;
      this.wednesdayHours.isClosed = this.filterHoursByWeekDay(2).venue_closing == "00:00:00" && this.filterHoursByWeekDay(2).venue_opening == "00:00:00";

      this.thursdayHours.opening = this.filterHoursByWeekDay(3).venue_opening;
      this.thursdayHours.closing = this.filterHoursByWeekDay(3).venue_closing;
      this.thursdayHours.isClosed = this.filterHoursByWeekDay(3).venue_closing == "00:00:00" && this.filterHoursByWeekDay(3).venue_opening == "00:00:00";

      this.fridayHours.opening = this.filterHoursByWeekDay(4).venue_opening;
      this.fridayHours.closing = this.filterHoursByWeekDay(4).venue_closing;
      this.fridayHours.isClosed = this.filterHoursByWeekDay(4).venue_closing == "00:00:00" && this.filterHoursByWeekDay(4).venue_opening == "00:00:00";

      this.saturdayHours.opening = this.filterHoursByWeekDay(5).venue_opening;
      this.saturdayHours.closing = this.filterHoursByWeekDay(5).venue_closing;
      this.saturdayHours.isClosed = this.filterHoursByWeekDay(5).venue_closing == "00:00:00" && this.filterHoursByWeekDay(5).venue_opening == "00:00:00";

      this.sundayHours.opening = this.filterHoursByWeekDay(6).venue_opening;
      this.sundayHours.closing = this.filterHoursByWeekDay(6).venue_closing;
      this.sundayHours.isClosed = this.filterHoursByWeekDay(6).venue_closing == "00:00:00" && this.filterHoursByWeekDay(6).venue_opening == "00:00:00";
    },
    emitVenueHours(): void {
      const openingHoursPayload: object = {
        monday: {
          venue_opening: this.formatOpeningHoursForApi(this.mondayHours.isClosed, this.mondayHours.opening),
          venue_closing: this.formatClosingHoursForApi(this.mondayHours.isClosed, this.mondayHours.opening, this.mondayHours.closing)
        },
        tuesday: {
          venue_opening: this.formatOpeningHoursForApi(this.tuesdayHours.isClosed, this.tuesdayHours.opening),
          venue_closing: this.formatClosingHoursForApi(this.tuesdayHours.isClosed, this.tuesdayHours.opening, this.tuesdayHours.closing)
        },
        wednesday: {
          venue_opening: this.formatOpeningHoursForApi(this.wednesdayHours.isClosed, this.wednesdayHours.opening),
          venue_closing: this.formatClosingHoursForApi(this.wednesdayHours.isClosed, this.wednesdayHours.opening, this.wednesdayHours.closing)
        },
        thursday: {
          venue_opening: this.formatOpeningHoursForApi(this.thursdayHours.isClosed, this.thursdayHours.opening),
          venue_closing: this.formatClosingHoursForApi(this.thursdayHours.isClosed, this.thursdayHours.opening, this.thursdayHours.closing)
        },
        friday: {
          venue_opening: this.formatOpeningHoursForApi(this.fridayHours.isClosed, this.fridayHours.opening),
          venue_closing: this.formatClosingHoursForApi(this.fridayHours.isClosed, this.fridayHours.opening, this.fridayHours.closing)
        },
        saturday: {
          venue_opening: this.formatOpeningHoursForApi(this.saturdayHours.isClosed, this.saturdayHours.opening),
          venue_closing: this.formatClosingHoursForApi(this.saturdayHours.isClosed, this.saturdayHours.opening, this.saturdayHours.closing)
        },
        sunday: {
          venue_opening: this.formatOpeningHoursForApi(this.sundayHours.isClosed, this.sundayHours.opening),
          venue_closing: this.formatClosingHoursForApi(this.sundayHours.isClosed, this.sundayHours.opening, this.sundayHours.closing)
        }
      }

      this.$emit('setVenueHours', openingHoursPayload);
    }
  },

  watch: {
    venueOpeningHours: {
      handler(value: string): void {
        if (value) {
          this.setVenueHours();
        }
      },
      deep: true
    }
  }
})
